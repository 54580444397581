<template>
  <!-- 新增采货单 -->
  <div class="addPicking">
    <!-- Element ui的dialog弹窗 -->
  <el-dialog title="新增采购单" :visible.sync="display.isShow" :close-on-click-modal="false">
    <!-- 内部dialog -->
    <el-dialog width="40%" title="地图" :visible.sync="isMap.isshow" append-to-body :close-on-click-modal="false">
        <!-- 搜索页面 -->
        <div id="search_ipt">
          <searchs></searchs>
        </div>
        <!-- map地图页面 -->
        <maps :isMap="isMap"></maps>
        <div slot="footer" class="dialog-footer">
          <el-button @click="isMap.isshow = false">取 消</el-button>
          <el-button type="primary" @click="isMap.isshow = false">确定</el-button>
        </div>
      </el-dialog>
    <!-- Element ui的验证表单 -->
    <div class="order_inform">
      <h4>订单信息</h4>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm" >
        <!-- 代理商 -->
        <el-form-item label="代理商" prop="agents" class="agents">
          <el-select v-model="form.agents" style="width:80%">
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
          <!-- 订单客户 -->
        <el-form-item label="订单客户" prop="clients">
          <el-input v-model="form.clients" style="width:80%"></el-input>
        </el-form-item>
        <!-- 收件人 -->
        <el-form-item label="收件人" prop="addressee">
          <el-input v-model="form.addressee" style="width:80%"></el-input>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone" style="width:80%"></el-input>
        </el-form-item>
        <!-- 收件地址 -->
        <el-form-item label="收件地址" prop="detailAddress" class="receiptAddress">
          <el-cascader
                size="large"
                :options="optionsDetail"
                v-model="form.province"
                @change="handleChangeDetail" style="width:80%;">
              </el-cascader>
        </el-form-item>
         <!-- 详细地址 -->
         <el-form-item class="detailAddress" prop="detailAddress">
          <el-input v-model="form.detailAddress" placeholder="详细地址" suffix-icon="el-icon-map-location" style="width:80%"></el-input>
          <div class="map_ipt" @click="isMap.isshow = true"></div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 设备信息 -->
    <div class="equipmentInform">
      <h4>设备信息</h4>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <!-- 网关数量 -->
        <el-form-item label="网关数量" prop="gatewayNumber" class="gatewayNumber">
          <el-input-number v-model="form.gatewayNumber" size="medium" @change="handleChange" :min="100" :max="1000"></el-input-number>
        </el-form-item>
        <!-- 回路数量--1P -->
        <el-form-item label="回路数量" prop="loopNumber" class="loop_1p loop_1ps">
          <el-select v-model="form.checkedLoop1" placeholder="1P" style="width:74px;margin-right:20px">
            <el-option :label="item.value" :value="item.value" v-for="item in loopName" :key="item.id"></el-option>
          </el-select>
          <el-input-number v-model="form.loopNumber1" size="medium" @change="handleChange" :min="100" :max="1000" class="loop"></el-input-number>
        </el-form-item>
        <!-- 回路数量--2P -->
        <el-form-item class="loop_1p loop_2p" prop="checkedLoop2">
          <el-select v-model="form.checkedLoop2" placeholder="2P" style="width:74px;margin-right:20px">
            <el-option :label="item.value" :value="item.value" v-for="item in loopName" :key="item.id"></el-option>
          </el-select>
          <el-input-number v-model="form.loopNumber2" size="medium" @change="handleChange" :min="100" :max="1000" class="loop"></el-input-number>
        </el-form-item>
        <!-- 塑壳数量 -->
        <el-form-item label="塑壳数量" prop="moldedShellNumber" class="moldedShell">
          <el-input-number v-model="form.moldedShellNumber" size="medium" @change="handleChange" :min="100" :max="1000"></el-input-number>
        </el-form-item>
        <!-- 回路数量--3P -->
        <el-form-item class="loop_1p loop_3p">
          <el-select v-model="form.checkedLoop3" placeholder="3P" style="width:74px;margin-right:20px"> 
            <el-option :label="item.value" :value="item.value" v-for="item in loopName" :key="item.id"></el-option>
          </el-select>
          <el-input-number v-model="form.loopNumber3" size="medium" @change="handleChange" :min="100" :max="1000" class="loop"></el-input-number>
        </el-form-item>
        <!-- 回路数量--4P -->
        <el-form-item class="loop_1p loop_4p" prop="checkedLoop4">
          <el-select v-model="form.checkedLoop4" placeholder="4P" style="width:74px;margin-right:20px">
            <el-option :label="item.value" :value="item.value" v-for="item in loopName" :key="item.id"></el-option>
          </el-select>
          <el-input-number v-model="form.loopNumber4" size="medium" @change="handleChange" :min="100" :max="1000" class="loop"></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <!-- 附件信息 -->
    <div class="annexInform">
      <h4>附件信息</h4>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
        <!-- 关联项目 -->
        <el-form-item label="关联项目" prop="relevancyDoc">
          <el-select v-model="form.relevancyDoc" placeholder="请选择所关联的项目" style="width:80%">
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
        <!-- 生效时间 --element ui 日期选择器 -->
        <el-form-item label="生效时间" prop="effectiveTime" class="effectiveTime">
          <el-date-picker v-model="form.effectiveTime" type="date" placeholder="选择日期" style="width:80%">
        </el-date-picker>
        </el-form-item>
        <!-- 采购金额 -->
        <el-form-item label="采购金额" prop="amount">
          <el-input v-model="form.amount" style="width:80%"></el-input>
        </el-form-item>
        <!-- 采购合同 -->
        <el-form-item label="采购合同" prop="purchaseAccount" class="procurementDoc">
          <el-upload class="avatar-uploader" :show-file-list="false" :on-change="documentUpload" action="#" :auto-upload=false >
         <img src="../../../assets/image/user_detail/group_349.png" alt="">
         <div slot="tip" class="el-upload__tip">*仅支持jpg、jpeg、pdf、zip格式</div>
       </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button  @click="resetForm()">取 消</el-button>
      <el-button type="primary" @click="display.isShow = false">确 定</el-button>
    </div>
</el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
import {CodeToText,regionDataPlus  } from 'element-china-area-data';
import { msg } from "../../../common/public";
// 引入地图和输入
import maps from "../../customerInformation/components/map.vue";
import searchs from "../../customerInformation/components/search.vue";
export default {
  props:['display'],
  components:{
    maps,
    searchs
  },
  data() {
    return {
      optionsDetail:regionDataPlus,//城市县信息
      isMap:{//地图的dialog的显示
        isshow:false
      },
    };
  },
  computed: {
    ...mapState('purchaseManage',['form','rules','loopName'])
  },
  mounted() {
    
  },

  methods: {
    // 计数器的方法
    handleChange(value) {
        console.log(value);
      },
    // 采购合同
    documentUpload(file){
      console.log(file.raw);
    },
    handleChangeDetail(){
      let location = '';//获取选择的地址
      if (this.form.province[0] == '') { //说明第一项选择了全部  提醒选择详细地址
        msg('请选择你所在省','error');
        // 并清空输入框
        return false;
      }
      if (this.form.province[1] == '') {
        msg('请选择你所在市的详细地址','error')
        return false;
      }
      if (this.form.province[2] == '') {
        msg('请选择你所在区/县的详细地址','error')
        return false;
      };
      // 将地区码转话为地址
      for (let i = 0; i < this.form.province.length; i++) {
        location += CodeToText[this.form.province[i]];
      }
      // console.log(location);
    },
      // 重置表单
      resetForm(){
       for(let i in this.form){
        this.form[i] = ''
       };
       this.display.isShow = false;
       
      },
   
  },
};
</script>

<style scoped>
/* 地图输入框的样式 */
#search_ipt{
  position: absolute;
  z-index: 999;
  position: absolute;
  top: 100px;
  left: 38px;
}
::v-deep .el-dialog{
  width: 80% !important;
  margin-top: 2vh !important;
}
/* dialog弹窗的title */
.addPicking /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
  color: #171717;
}
.order_inform>h4{
  font-family: 'SHSCN_Bold';
  color: #171717;
  margin:0 0 2.78vh 1.8vw;
}
/* 订单信息的表单区域 */
.order_inform /deep/ .el-form{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
/* 详细地址的末尾小图标 */
.detailAddress /deep/ .el-input__icon {
  color: #0666F5;
}
.map_ipt{
  width: 20px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

/* 设备信息h4标题 */
.equipmentInform h4{
  font-family: 'SHSCN_Bold';
  color: #171717;
  margin:0.5vh 0 2.78vh 1.8vw;
}
/* 设备信息的小图标的行高 */
.equipmentInform /deep/ .el-input__icon{
  line-height: 3.5vh;
}

/* 设备信息--计数器的背景颜色和按钮颜色 */
/* 背景色 */
.equipmentInform /deep/ .el-input-number__decrease{
  vertical-align: bottom;
  background-color: #fff;
}
.equipmentInform /deep/ .el-input-number__increase{
  border-bottom: 1px solid #CFCECE;
  background-color: #fff;
}
/* 按钮的颜色 */
.equipmentInform /deep/ .el-icon-plus{
  color: #0666F5;
}
.equipmentInform /deep/ .el-icon-minus{
  color: #0666F5;
}
/* 设备信息下的下拉框和计数器的高度 */


/* 设备信息--计数器和下拉框的样式 */
.equipmentInform /deep/ .el-form{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
/* 1P、2P、3P、4P的样式 */
.loop_1p /deep/ .el-form-item__content{
  display: flex;
}
.loop_1p /deep/ .el-input {
  /* margin-right: 0.8vw; */
  padding: 0 0 0 0;
}
.loop_1p /deep/ .el-input__inner{
  vertical-align: bottom;
}
/* 1P-4P数字选择器的宽度 */
.loop /deep/ .el-input{
  width: 137px !important;
}
/* 塑壳数量 */
.moldedShell /deep/ .el-input__inner{
  height: 40px !important;
  vertical-align: bottom;
}
.moldedShell /deep/ .el-input-number__increase{
  height: 38px !important;
  border-bottom: none;
}
.moldedShell /deep/ .el-input-number__decrease{
  height: 38px !important;
  border-bottom: none;
}

.el-input-number--medium .el-input-number__decrease, .el-input-number--medium .el-input-number__increase{
  width: 36px;
  height: 38px;
}
.gatewayNumber  /deep/ .el-input__inner{
  height: 40px !important;
}
/* 增加 */
.gatewayNumber /deep/ .el-input-number__increase{
  height: 37px !important;
  border-bottom: none;
  top: 1px;
}
/* 减少 */
.gatewayNumber /deep/ .el-input-number__decrease{
  height: 37px !important;
  border-top: none;
}
/* 1P-4P的数字选择器的高度 */
.loop_1p  /deep/ .el-input__inner{
  height:40px !important;
  /* border: 1px solid #DCDFE6; */
}
/* 增加 */
.loop_1p /deep/ .el-input-number__increase{
  height: 37px !important;
  border-top: none;
  border-bottom: none;
  top: 2px;
  right: 65px;
}
/* 减少 */
.loop_1p /deep/ .el-input-number__decrease{
  height: 37px !important;
  border-top: 1px none;
  top: 1px;
}

/* 附加信息 */
.annexInform h4{
  font-family: 'SHSCN_Bold';
  color: #171717;
  margin:0.5vh 0 2.78vh 1.8vw;
}
.annexInform /deep/ .el-form{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
/* 采购合同 */
.procurementDoc /deep/ .el-upload__tip{
  margin-top: -1vw;
  font-family: 'SHSCN_Regular';
  color:#B2B2B2 ;
}
/* 多媒体查询 */
/* 屏幕大于1920px */
@media screen and (min-width: 1920px) {
  .loop_1p /deep/ .el-input-number__increase{
  height: 37px !important;
  border-top: none;
  border-bottom: none;
  right: 65px;
}
}
</style>