<template>
  <!-- 采购管理-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" height="600">
       <!-- 索引序号 -->
       <el-table-column type="index" min-width="100" fixed></el-table-column>
        <!-- 订单号 -->
        <el-table-column prop="ordersNumber" label="订单号" min-width="300">
        </el-table-column>
        <!-- 订单客户 -->
        <el-table-column prop="ordersClients" label="订单客户" min-width="300">
        </el-table-column>
        <!-- 采购日期 -->
        <el-table-column prop="purchaseData" label="采购日期" min-width="300">
        </el-table-column>
        <!-- 合同状态 -->
        <el-table-column prop="contractStatus" label="合同状态" min-width="300">
          <template slot-scope="scope">
            <p v-if="scope.row.contractStatus == true">已签署</p>
            <p v-else>已签署</p>
          </template>
        </el-table-column>
        <!-- 采购金额 -->
        <el-table-column prop="purchaseSum" label="采购金额" min-width="300">
        </el-table-column>
        <!-- 订单状态 -->
        <el-table-column prop="ordersStatus" label="订单状态" min-width="300">
          <template slot-scope="scope">
            <div v-if="scope.row.ordersStatus == '0'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_40.png" alt="" style='margin-right:0.2vw '>已完成
            </div>
            <div v-else-if="scope.row.ordersStatus == '1'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style='margin-right:0.2vw '>待发货
            </div>
            <div v-else-if="scope.row.ordersStatus == '2'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_37.png" alt="" style='margin-right:0.2vw '>待处理
            </div>
            <div v-else>
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style='margin-right:0.2vw '>处理中
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="详情" min-width="100" fixed="right">
          <template slot-scope="scope">
            <el-link type="primary" @click="jumpToDetail(scope.row)">详情</el-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="currentPage" :page-sizes="[10]" :page-size="100"
        layout="total, prev, pager, next,sizes,jumper" :total="100">
      </el-pagination>
    </template>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  components: {
  },

  data () {
    return {
      currentPage:1,//当前页数
    };
  },
  computed: {
   ...mapState('purchaseManage',['tableData'])
  },
  mounted () {
  },

  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转到采购详情页
    jumpToDetail(inform){
      this.$router.push({ path: '/purchaseDetail'})
      // 将需要的传递的信息存储到localStorage
      let informs = JSON.stringify(inform)
      sessionStorage.setItem('informs',informs);
    }
  },
};
</script>

<style  scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists .el-pagination{
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}
.lists /deep/ .el-pagination__jump{
  margin-left: 0;
}
.el-table /deep/ .el-table__cell{
  text-align: center;
  padding: 16px 0;
}
</style>